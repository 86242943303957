.settings-parent-container{
  display: flex;
  flex-direction: row;
}
.settings-left-menu-container{
  flex: 30%;
  margin:0 1% 0 0;
  background-color: #fff;
  padding-top: 2%;
  padding-bottom: 2%;
  border-radius: 8px;
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  box-shadow: 0 0 16px 1px rgb(218, 216, 216);
  overflow-y: scroll;
}
.settings-left-menu-container::-webkit-scrollbar{
  width: 2px;
}
.settings-left-menu-container::-webkit-scrollbar-thumb{
  background-color: #b6c0fc;
}
.settings-left-menu-container div:hover{
  background-color:#eeeff8 ;
}
/* .settings-left-menu-container button:focus{
  color:#002afc;
  background-color:#ebeefe;
  font-weight: 600;
  outline: none;
} */
.non-focus{
  margin:1% 0 1%;
  text-align: left;
  color: #000;
  width: 100%;
  height: 40px;
 background-color: transparent;
 border: none;
 cursor: pointer;
 font-size: 0.9rem;
 font-weight: 600;
 /* padding: 18px 4px 38px 48px; */
 padding: 9px 4px 28px 48px;
 
}
.focus-settings{
  color:#002afc !important;
  background-color:#ebeefe !important;
  font-weight: 600 !important;
}
.settings-right-contents-container{
  flex:70%
}
.hide{
  display:none;
}

.hideUtility{
  display: none;
}


.show{
  display: block;
}

/* rewards and point settings css */

.settings-rewards-container{
  width: 35vw;
}
.settings-rewards-container-top{
  background-color: #fff;
  width: 100%;
  padding: 3%;
  border-radius: 4px;
  box-shadow: 0 0 16px 1px rgb(218, 216, 216);
  margin-bottom: 2%;
}
.settings-rewards-body{
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 3%;
  
}
.settings-rewards-body .global-single-input{
  width: 45%;
}
.settings-reward-btn{
  float: right;
  margin-right: 0 !important;
  width: 25% !important;
}
.settings-reward-input{
  position: relative;
}
.settings-reward-input input{
  position: absolute;
  top: 40%;
  border-radius: 4px 0px 0px 4px;
  width: 75%;
}
.settings-reward-drop-down{
  position: absolute !important;
  left: 75%;
  top: 40%;
  border-radius: 0px 4px 4px 0px !important;
  width: 25% !important;
  border-left: 1px solid #f3f3f3 !important;
}

.settings-template-container{
  background-color: white;
  border-radius: 1em;
  height: 85vh;
  padding: 2em;
}
.settings-tab-switch{

}
/* new tab  */
.settings-new-tab-switch-container {
width: 100%;
}

.settings-new-tab-switch-header {
/* border-radius: 1em; */
/* padding: 0.5em; */
display: flex;
/* border-bottom: 1px solid #ccc; */
margin-bottom: 20px;
box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.1)
}

.settings-new-tab-switch-item {
padding: 10px 20px;
cursor: pointer;
font-weight: 500;
color: #000;
}

.settings-new-tab-switch-item.active {
color: blue;
/* border-bottom: 2px solid blue; */
}

.settings-new-tab-switch-body {
/* padding: 20px; */
/* background-color: #f9f9f9; */

}

.settings-new-tab-switch-content {
padding: 10px;
/* border: 1px solid #ddd; */
border-radius: 4px;
background-color: #fff;
overflow-y: auto;
height: 70vh;

}


/* tab body layout  */
.setting-tab-layout-container {
display: flex;
flex-direction: column;
align-items: center;
}

.setting-tab-layout-tabs {
display: flex;
flex-wrap: wrap;
gap: 1em;
/* justify-content: space-around; */
width: 100%;
margin-top: 20px;
}

.setting-tab-layout-item {
display: flex;
flex-direction: column;
align-items: center;
margin: 0 20px;
}

.setting-tab-layout-item label {
display: flex;
align-items: center;
cursor: pointer;
}

.setting-tab-layout-item input[type='radio'] {
margin-right: 10px;
}

.setting-tab-layout-preview {
margin-top: 10px;
}

.setting-tab-layout-preview img {
width: 200px; /* Adjust based on your preferred size */
height: auto;
border: 1px solid #ddd;
border-radius: 4px;
}

.setting-tab-layout-selected-info {
margin-top: 20px;
font-size: 18px;
font-weight: bold;
}



/* Template Model */

.global-print-total-field{
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.print-first-footer{
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  /* position: fixed; */
  bottom: 70px;
  left: 20px;
  right: 20px;
}
.line-sign{
  border: 1px solid #c1c0c0;
    /* margin: 0 10px; */
    width: 130%;
}

.print-footer{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  height: 30px;
  text-align: start;
  background-color: rgb(177 205 176);
}

.template-head{
  background-image: url("../../Assets/Images/Group\ 16762.png");
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  height: 145px;
  width:58%;
 padding-top: 10px;
 text-align: center;

}
.template-head p{
  color: white;
  font-size: large;
  margin: 0px;
 
}
.template2-print-header{
  display: flex;
  justify-content: space-between;
  margin-top: 9px;
  
}
.template-head-left{
  background-image: url("../../Assets/Images/Group\ 16763.png");
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  height: 145px;
  width:50%;
 padding-top: 10px;
 text-align: center;
}
.template-head-left P{
margin: 0;
color: white;
}
.template2-first-box{
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
  border: 1px solid rgb(92, 92, 92);
  padding: 10px 50px 10px 50px;
}
.template2-container{
  padding-inline: 20px;
  padding-top: 10px;
}

.template2-container table{
  margin-top: 5px;
  width: 100%;
  border: 1px solid black;
  border-top: 0px;
  border-collapse: collapse;
}
.template2-container table th{
align-items: center;
font-size: small;
}
.template2-container thead{
  background-color: #4d689a;
  color: white;
}
.template2-container td{
  border: 1px solid black;
  border-top: 0px;
  border-bottom: 0px;
  font-size: small;
}
.print-total-vat{
  color: white;
display: flex;
width: 50%;
background-color: #4d689a;
justify-content: space-between;
padding-inline: 5px;
margin: 0;
}
.print-table-head td{
  padding-inline: 2px;
}
.global-print-heading{
  text-align: center;
  background-color: #dfdddd;
  padding: 2px;

}
.print-main-div{
  padding: 20px;
}
.first-container{
  display: flex;
  justify-content: space-between;
}
.Table-box{
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 10px;
}
.t-box{
  background-color: white;
  border: 1px solid rgb(25, 25, 25);
  width: 34%;
  padding: 10px;
}
.t-box p{
  margin: 0px;
  font-size: small;
}
.print-table-head{
  background-color: #6b972d;
 
}
.print-table-head th{
  color: white;
  border: 1px solid rgb(50, 50, 50);
}
.print-table-head td{
border-left: 1px solid gray;
border-right: 1px solid gray;
border-bottom: 1px solid gray;
}

/* vendor payment print */

.secound-container{
display: flex;
padding: 35px 0px 47px 0px;
justify-content: space-between;

}
.secound-container p{
font-size: small;
padding: 3px;
}

.res-label p{
  background-color: #ebe9e9;
  width: 145px;
  padding: 3px;
  border-radius: 1px;
}
.table-div table{
  width: 100%;
  border-collapse: collapse;
  border: 1px black solid;
}
.table-div tr th{
  background-color: #1b5d9f;
  color: white;
  font-weight: 400;
  font-size: small;
}
.table-div table td{
  border: 1px black solid;
}

.first-print-footer{
  display: flex;
  place-content: space-between;
}

