@page {
  size: A4;
  margin: 11mm 17mm 17mm 17mm;
}

@media print {
  footer {
    position: fixed;
    bottom: 0;
  }

  .content-block,
  p {
    page-break-inside: avoid;
  }

  html,
  body {
    width: 210mm;
    height: 297mm;
  }
}

.new-print-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; 
  font-size: 10px;
}
.new-print-table th, .new-print-table .new-layout-print-footer td{
  background-color: #1d1da7;
  font-weight: 600;
  color: white;
}

.new-print-table th, .new-print-table td {
  padding: 8px;
  border: 1px solid #ddd;
  word-wrap: break-word; 
  white-space: normal;   
  overflow-wrap: break-word; 
  
}


@media print {
  body {
    overflow: visible !important;
    height: auto !important;
  }

  .printer-div-list {
    display: none !important; /* Hide print button while printing */
  }

  /* .updated-table-container {
    
    overflow: visible !important;
    height: auto !important;
    width: 100% !important;
  } */
}

@media print {
  /* Ensuring the table takes up the full page */
  .new-global-table-container {
    page-break-before: always;
    page-break-after: always;
    width: 100%;
    overflow: visible;
    white-space: nowrap;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  td, th {
    padding: 5px;

    text-align: left;
  }

  tr {
    page-break-inside: avoid;
  }

  /* Avoid cutting off the last row */
  body {
    margin: 0;
    padding: 0;
  }

  .printer-div-list {
    display: none; /* Hide print button on printed page */
  }
  .global-report-table-container {
    page-break-before: always;
    page-break-after: always;
    width: 100%;
    overflow: visible;
    white-space: nowrap;
  }
  .global-table-container{
    page-break-before: always;
    page-break-after: always;
    width: 100%;
    overflow: visible;
    white-space: nowrap;
  }
}
