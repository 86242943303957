.MuiDialog-container .virtualised-table-ctn .ant-table-container table {
  width: auto !important;
}
.new-global-white-bg-container-fg{
  background-color: #fff;
}
.add-line-btn{
  border: none;
    background-color: transparent;
    color: #685391;
    font-weight: 800;
    margin: 5px;
    font-size: 0.8rem;
    cursor: pointer;
}
.btn-action-blue{
  width: auto;
  height: 30px;
  font-size: 0.7rem;
  font-weight: 700;
  background-color: #1724d8;
  margin: 4px;
}

.production-table table thead th{
    background: #eef1fa !important;
    text-align: center !important;
}
.production-table table tbody tr td{
    padding:4px 8px!important;
    height: 35px !important;
}

.table-row-light {
    background-color: #fafbff !important;
  }
  
  .table-row-dark {
    background-color: white !important;
  }

  .production-create-table table thead th{
    background: #ffffff !important;
    padding: 2px 10px !important;
    height: 45px;
    font-size: 1rem !important;
}
.production-create-table table tbody tr td {
  padding: 4px 6px!important;
  height: 40px!important;
}
.production-create-table table tbody tr.ant-table-measure-row {
  display: none;
}
.production-create-table table tbody tr td input.production-table-textfield{
  border: none;
  height: 39px;
  padding: 10px 4px;
  background-color: #f6f7fc;
  border-bottom: 2px solid #afa8a8;
  width: 100%;
}
.production-create-table table tbody tr td .MuiAutocomplete-root {
  height: 40px;
}

.production-create-table table tbody tr td .MuiInputBase-root {
  background-color: #f6f7fc; 
  font-weight: bold;
  padding: 0 6px;
  border-radius: 4px 4px 0px 0px !important;
  border-bottom: 2px solid rgb(175, 168, 168);
}
.production-create-table table tbody tr td .MuiInputBase-root input{

  height: 36px;
  padding: 2px 4px!important;
}
.production-create-table table tbody tr td fieldset{
border: none;
}


/* ======= production category */
.production-card{
    width:30%;
    min-width: 250px;
    max-width: 410px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-left: 15px;
   
    box-shadow: 1px 2px 5px 1px #e8e8e8;
    background-color: #edf2f9;
    margin: 1%;
}
.Box-head p{
    margin: 0;
    padding-top: 6px;
    font-weight: 400;
    font-size: large;
}
.name-div{
border: 1px solid;
    border-color: #afafaf;
    border-radius: 6px;}

    .name-div p{
        margin: 0;
    padding: 15px 15px 15px 15px;
    }
    .production-card span{
        padding-top: 14px;
    }

    .production-dailogbox{
        padding-inline: 26px;
        display: grid;
    }
    .production-dailogbox p{
        font-size: larger;
    font-weight: 500;
    }
    #userInput{
        border: none;
        background-color: #f0f0f9;
    padding: 15px;
    }
   .dailog-btn{
    justify-self: center;
    padding: 21px;
    width: 100%;
    text-align: center;   
    
   }
   .dailog-btn button{
    background-color: blue;
    color: white;
    width: 60%;
    font-weight: bold;

   }

.status-DRAFT {
  color: orange;
  font-weight: bold;
}

.status-TRANSFER {
  color: rgb(192, 2, 2);
  font-weight: bold;
}

.status-DONE {
  color: blue;
  font-weight: bold;
}
.received-qty-blue {
  color: blue;
}
