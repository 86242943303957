.autocomplete-input-btn{
    background-color: green!important;
    color: #fff!important;
    height: 25px!important;
    width: 25px!important;
    border-radius: 3px!important;
    font-size: 16px!important;
}

.outlined-mui-autocomplete .MuiOutlinedInput-root{
    padding-right: 10px !important;
}