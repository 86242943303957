.tab-menu-button {
    padding: 16px;
    cursor: pointer;
    position: relative; /* Needed for the underline */
}

.tab-menu-button.active {
    color: #2e06ff;
    font-weight: 600;
}


.tab-menu-button.inactive {
    color: #818181;
}