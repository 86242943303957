.top-main-container {
  background-color: white;
  padding: 1px;
  width: 99%;
  margin-left: 5px;
  margin-top: 9px;
}
.first-main-heading-div {
  box-shadow: 0 8px 8px -6px #b5afaf;
  display: flex;
  width: 100%;
}

.first-main-container-div {
  display: flex;
  background-color: white;
}

.first-main-container-div p {
  margin: -1px 0px;
  border-right: 2px solid #4e60bb;
  padding: 0px 13px;
  padding: 9px 13px;
  font-weight: 500;
  color: #c2cbf9;
  font-size: 14px;
  cursor: pointer;
}
.date-con-div {
  display: flex;
  /* margin-right: -47px; */
}
.secound-main-container-div {
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 1px 30px;
  padding-top: 30px;
}
.table-header {
  display: flex;
  flex-direction: column;
  height: 82px;
}
.table-header p {
  margin: 0;
  margin-top: auto;
  font-size: 20px;
  font-weight: 600;
}
.top-main-container table {
  width: 100%;
}
.top-main-container th {
  background-color: #1d1da7;
  color: white;
  font-size: smaller;
  width: 15%;
}

.top-main-container tr {
  cursor: pointer;
}
.top-main-container td {
  background-color: #c3d4f7;
  text-align: end;
  padding-right: 10px;
}
.table th {
  border: 1px solid #c2cbf9;
}
.table td {
  border: 1px solid #c2cbf9;
  background-color: white;
  font-size: 0.8rem;
}

.table-container {
  max-height: 60vh;
  overflow-y: auto;
  position: relative;
  padding-inline: 10px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  position: relative;
  border-spacing: inherit;
}

.table-body {
  display: block;
  max-height: inherit;
  overflow-y: auto;
}

.table thead {
  position: sticky;
  top: 0;
  background-color: #1d1da7;
  color: white;
  z-index: 2;
}

.table tfoot {
  text-align: end;
  position: sticky;
  bottom: 0;
  background-color: #1d1da7;
  color: white;
  z-index: 2;
}

.table td,
.table th {
  border: 1px solid #c2cbf9;
  white-space: nowrap;
  padding: 4px;
}

.table-head td {
  background-color: rgb(38, 74, 149);
  text-align: center;
  color: white;
  font-weight: 500;
  /* padding-bottom: 6px;
    padding-top: 5px; */
  border: 1px solid white;
  font-size: 0.8rem;
}

.table-head2 td {
  font-weight: 500;
  text-align: center;
  background-color: #c2cbf9;
  border: 1px solid white;
}

/* Adjust the width of the scrollbar */
.table-container::-webkit-scrollbar {
  width: 5px;
}

.table-container::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #888;
}
.drop-down-rows {
  display: flex;
  /* width: 80%; */
  align-self: self-end;
  margin-right: 17px;
  margin-left: 290px;
  justify-content: space-between;
  padding-inline: 23px;
}
.drop-down-row-line {
  border: 1px solid #e3e8f5;
  width: 80%;
  margin-right: 16px;
  margin-top: 0;
}
.gl-table-div {
  overflow: auto;
  max-height: 65vh;
  position: relative;
}

.gl-table-div thead {
  position: sticky;
  top: 0px;
  background-color: #edf2f9;
  height: 36px;
  z-index: 2;
}
/* Adjust the width of the scrollbar */
.gl-table-div::-webkit-scrollbar {
  width: 5px;
}

.gl-table-div::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.gl-table-div::-webkit-scrollbar-thumb {
  background-color: #888;
}

/* Adjust the width of the scrollbar */
.global-report-table-container::-webkit-scrollbar {
  width: 5px;
}

.global-report-table-container::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.global-report-table-container::-webkit-scrollbar-thumb {
  background-color: #888;
}

.action-btns-container > div {
  display: flex;
  justify-content: center;
  height: 4em;
  width: 3em;
  align-items: center;
}
.print-container-trail-balance {
  padding: 1em;
}
.print-container-trail-balance .trailbalnce-print-header {
  padding: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.trailbalnce-print-header .trail-balance-print-title span {
  font-size: 1.5em;
  font-weight: bold; 
  text-align: center;
}
.trailbalnce-print-header .trail-balance-print-title {
display:flex;
flex-direction:column;
align-items:center ;
justify-content: center;

}
.trailbalnce-print-header .company-details-container {
  display: flex;
  flex-direction: column;
}
.print-container-trail-balance .table-container {
  max-height: none;
}
.print-container-trail-balance table tfoot tr td {
  border: transparent;
}

/* general ledger nw  css */

.print-general-ledger-container {
  page-break-after: always;
}
.print-general-ledger-container .gl-table-div {
  width: 100%;
  max-height: none;
}
.print-general-ledger-container .general-ledger-table {
  padding: 0.5em;
  /* background-color: red; */
  width: 100%;
  border-collapse: collapse;
}
.general-ledger-table tr th {
  background-color: #1d1da7;
  color: white;
}
.general-ledger-table tbody tr td {
  border: 1px solid #888;
  /* padding: 0.5em; */
  border-collapse: collapse;
}
.total-row-footer td {
  font-size: 1em;
  font-weight: 600;
  padding: 1em;
  background-color: #1d1da7;
  color: white;
  border: 1px solid #1d1da7 !important;
  border-collapse: collapse;
  margin-top: 10px;
}
