.updated-global-page-parent-container{
    margin: 8px;
    max-height: calc(100vh - 80px);
    /* overflow-y: hidden; */
}
.list-create-head-bar{
    background-color: #fff;
    padding: 0 2%;
}
.list-create-head-bar .create-head-buttons-container{
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.create-head-buttons-container i{
    color: #fff;
    font-size: medium;
    padding: 8px;
    border-radius: 2px;
}
.create-head-buttons-container button.btn-primary{
    max-width: 90px;
    min-width: 55px;
    margin: 6px;
}
.create-head-buttons-container .view-order-search-container{
    width: fit-content;
    margin: 0;
}

.pagination-container{
    background-color: #fff;
    margin-top: 5px;
    padding: 8px 11px;
    padding-bottom: 0;
}
.pagination-container .custom-paginations-ctn{
    width: fit-content;
    margin-left: auto;
    margin-top: 0px;

}
.pagination-container h4{
    width: fit-content;
    margin: 0;

}


.updated-table-container{
    height: calc( 100vh - 150px);
    max-height: fit-content;
}

.antd-table-container{
    background-color: #fff;
    padding: 2%;
}
.antd-table-container .antd-table th{
    background-color: #fff!important;
    font-weight: 600    !important;
    text-align: center!important;
}
.antd-table-container .antd-table td{
    background-color: #eee!important;
    border-bottom: 8px solid #fff!important;
    text-align: center!important;
    padding: 0px 10px!important;
}


.custom-star-hr {
    border: none;
    border-top: 1px dashed black; 
    text-align: center;
    height: 0;
    line-height: 1.5;
    margin: 20px 0;
  }
  
  .custom-star-hr::after {
    content: '********************';
    font-size: 16px;
    display: inline-block;
    position: relative;
    top: -10px;
    letter-spacing: 2px; 
  }



.self-container {
    --UnChacked-color: hsl(0, 0%, 10%);
    --chacked-color: hsl(7, 85%, 47%);
    --font-color: white;
    --chacked-font-color: var(--font-color);
    --icon-size: 18px;
    --anim-time: 0.2s;
    --anim-scale: 0.1;
    --base-radius: 0.8em;
    position: absolute;
    bottom: 50px;
    right: 0;
  }
  
  .self-container {
    display: flex;
        align-items: center;
        position: absolute;
        cursor: pointer;
        font-size: 12px;
        -webkit-user-select: none;
        user-select: none;
        fill: var(--font-color);
        color: var(--font-color);
        top: 10px;
        transform: translateY(-50%);
  }
  .self-container:focus, .self-container:focus-within {
    outline: none;
    box-shadow: none;
}
  .self-container input {
    display: none;
  }
  
  .self-checkmark {
    background: var(--UnChacked-color);
    border-radius: var(--base-radius);
    display: flex;
    padding: 0.5em;
  }
  
  .self-icon {
    width: var(--icon-size);
    height: auto;
    filter: drop-shadow(0px 2px var(--base-radius) rgba(0, 0, 0, 0.25));
  }
  
  .self-name {
    margin: 0 0.25em;
    font-size: 16px;
  }
  
  .self-Yes {
    width: 0;
  }
  
  .self-name.self-Yes {
    display: none;
  }
  
  .self-container:hover .self-checkmark,
  .self-container:hover .self-icon,
  .self-container:hover .self-name {
    transform: scale(calc(1 + var(--anim-scale)));
  }
  
  .self-container:active .self-checkmark,
  .self-container:active .self-icon,
  .self-container:active .self-name {
    transform: scale(calc(1 - var(--anim-scale) / 2));
    border-radius: calc(var(--base-radius) * 2);
  }
  
  .self-checkmark::before {
    content: "";
    opacity: 0.5;
    transform: scale(1);
    border-radius: var(--base-radius);
    position: absolute;
    box-sizing: border-box;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
  
  .self-checkmark:hover:before {
    background-color: hsla(0, 0%, 50%, 0.2);
  }
  
  .self-container input:checked + .self-checkmark::before {
    animation: boon calc(var(--anim-time)) ease;
    animation-delay: calc(var(--anim-time) / 2);
  }
  
  .self-container input:checked + .self-checkmark {
    --UnChacked-color: var(--chacked-color);
    fill: var(--chacked-font-color);
    color: var(--chacked-font-color);
  }
  
  .self-container input:checked ~ .self-checkmark .self-No {
    width: 0;
  }
  
  .self-container input:checked ~ .self-checkmark .self-name.self-No {
    display: none;
  }
  
  .self-container input:checked ~ .self-checkmark .self-Yes {
    width: var(--icon-size);
  }
  
  .self-container input:checked ~ .self-checkmark .self-name.self-Yes {
    width: auto;
    display: unset;
  }
  
  .self-container,
  .self-checkmark,
  .self-checkmark::after,
  .self-icon,
  .self-checkmark .self-name {
    transition: all var(--anim-time);
  }
  

  /* From Uiverse.io by vk-uiux */ 
.loader {
  position: relative;
  width: 33px;
  height: 33px;
  perspective: 67px;
}

.loader div {
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  left: 50%;
  transform-origin: left;
  animation: loader 2s infinite;
}

.loader div:nth-child(1) {
  animation-delay: 0.15s;
}

.loader div:nth-child(2) {
  animation-delay: 0.3s;
}

.loader div:nth-child(3) {
  animation-delay: 0.45s;
}

.loader div:nth-child(4) {
  animation-delay: 0.6s;
}

.loader div:nth-child(5) {
  animation-delay: 0.75s;
}

@keyframes loader {
  0% {
    transform: rotateY(0deg);
  }

  50%, 80% {
    transform: rotateY(-180deg);
  }

  90%, 100% {
    opacity: 0;
    transform: rotateY(-180deg);
  }
}